import { Input, Col, Row, Button, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "../../../container/ItemList.css";
import axios from 'axios';
// import ImageCombiner from './ImageCombiner';
import { getCartCount } from '../../../redux/cartCount/actionCreator';

const LetterCampaignList = () => {
    const [letterCampaignData, setLetterCampaignData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [warningModal, setWarningModal] = useState(false);
    const dispatch = useDispatch();


    const [campaignName, setCampaignName] = useState("");
    const [campaignId, setCampaignId] = useState("");
    const [campaignDesc, setCampaignDesc] = useState("");
    const [campaignStartDate, setCampaignStartDate] = useState("");
    const [campaignEndDate, setCampaignEndDate] = useState("");
    const [campaignEmailSub, setCampaignEmailSubject] = useState("");
    const [campaignEmailContent, setCampaignEmailContent] = useState("");
    const [campaignEmailAuth, setCampaignEmailAuth] = useState("");
    const [campaignTargetCount, setCampaignTargetCount] = useState("");
    const [campaignSentCount, setCampaignSentCount] = useState("");
    const [campaignRemainingCount, setCampaignRemainingCount] = useState(0);
    const [campaignImageUrl, setCampaignImageUrl] = useState("");

    // Form data state
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        city: "",
        postalCode: "",
    });

    // Handle form field changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent page reload
        let final_array = { id: campaignId }
        console.log("Form Submitted Data:", formData);
        const plainTextEmailContent = campaignEmailContent
            .replace(/<\/?[^>]+(>|$)/g, '') // Strip HTML tags
            .replace(/<br\s*\/?>/g, '\n') // Convert <br> to newlines
            .replace(/&nbsp;/g, ' '); // Replace HTML spaces with regular spaces

        axios.post(`${process.env.REACT_APP_API_URL}/update-letter-campaign-count`, final_array)
            .then(function (response) {
                if (response.data.status == true) {
                    // e.preventDefault();
                    const body = `Dear Authority,\n My name is ${formData.firstName} ${formData.lastName}, and I would like to bring your attention to the following matter:\n\n ${plainTextEmailContent}\n\n\n Best regards,\n ${formData.firstName} ${formData.lastName}\n Email: ${formData.email}`;
                    // Use mailto link to open user's email client with pre-filled data
                    window.location.href = `mailto:${campaignEmailAuth}?subject=${encodeURIComponent(campaignEmailSub)}&body=${encodeURIComponent(body)}`;

                }
            })
            .catch(function (error) {
                notification.error({
                    message: "Someting went wrong"
                });
                //handleImportCancel();
                return false;
            });

    };

    const [name, setName] = useState("Kuldip Awachar");
    const [email, setEmail] = useState("kuldipsawachar@gmail.com");
    const [message, setMessage] = useState("Thhis is the testing email we are sending");
    useEffect(() => {
        // Load cart items from local storage when the component mounts
        const savedCartItems = localStorage.getItem('donate_item_packages')
            ? JSON.parse(localStorage.getItem('donate_item_packages'))
            : [];
        setCartItems(savedCartItems);

        axios.get(`${process.env.REACT_APP_API_URL}/get-active-letter-campaign`)
            .then(function (response) {
                if (response.data.length > 0) {
                    // const initialData = response.data.map(item => {
                    //     // Find if the item is already in the cart
                    //     const savedItem = savedCartItems.find(cartItem => cartItem.id === item.package_id);
                    //     return {
                    //         ...item,
                    //         count: savedItem ? savedItem.count : 1 // Initialize count for each item
                    //     };
                    // });

                    setLetterCampaignData(response.data);
                    setSearchData(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    console.log("searchData *****", searchData)

    const addToCart = (id, name, amount, organisations, count) => {
        let updatedCart = [...cartItems];
        const existingItemIndex = updatedCart.findIndex(el => el.id === id);

        if (existingItemIndex > -1) {
            updatedCart[existingItemIndex].count = count;
        } else {
            let organisations_list = JSON.stringify(JSON.parse(organisations)).replace(/\"/g, "'");
            const newItem = { id, name, amount, organisations_list, count };
            updatedCart.push(newItem);
        }

        setCartItems(updatedCart);
        localStorage.setItem('donate_item_packages', JSON.stringify(updatedCart));
        if (getCartCount) {
            dispatch(getCartCount());
        }
    };

    const searchRecords = (searchText) => {
        const filteredData = letterCampaignData.filter(item =>
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.title.toLowerCase().includes(searchText.toLowerCase())
        );
        setSearchData(filteredData);
    };
    const updateCount = (id, newCount) => {
        const updatedData = searchData.map(item =>
            item.package_id === id ? { ...item, count: newCount } : item
        );
        setSearchData(updatedData);
        setLetterCampaignData(updatedData);
    };
    const showDetailsModal = (id, title, description, start_date, end_date, email_subject, email_content, email_authority, target_count, sent_count, image_url) => {
        setCampaignName(title);
        setCampaignId(id);
        setCampaignDesc(description);
        setCampaignStartDate(start_date);
        setCampaignEndDate(end_date);
        setCampaignEmailSubject(email_subject);
        setCampaignEmailContent(email_content);
        setCampaignEmailAuth(email_authority);
        setCampaignTargetCount(target_count);
        setCampaignSentCount(sent_count);
        // setCampaignSentCount(20);
        setCampaignRemainingCount(target_count - sent_count);
        // setCampaignRemainingCount(target_count - 20);
        setCampaignImageUrl(image_url);
        setWarningModal(true);
    };
    const handleModalCancel = () => {
        setWarningModal(false)
        setCampaignName("");
        setCampaignId("");
        setCampaignDesc("");
        setCampaignStartDate("");
        setCampaignEndDate("");
        setCampaignEmailSubject("");
        setCampaignEmailContent("");
        setCampaignEmailAuth("");
        setCampaignTargetCount("");
        setCampaignSentCount("");
        setCampaignImageUrl("");
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={{ marginTop: 10, paddingBottom: 60 }}>
                <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                    <Col xs={0} sm={0} md={2} lg={2}></Col>
                    <Col xs={24} sm={24} md={20} lg={20}>
                        <Row justify="center" style={{ margin: '10px 0' }}>
                            <Col span={24} md={12}>
                                <h2 className='item-container-title'>Letter Campaigns</h2>
                            </Col>
                            <Col span={24} md={12}>
                                <Input type='text' placeholder='Search here' onChange={(e) => { searchRecords(e.target.value) }} />
                            </Col>
                        </Row>
                        {searchData.length > 0 ? (
                            <div>
                                <div className="n-items-list">
                                    {searchData.map((item) => {
                                        if (item) {
                                            const isInCart = cartItems.some(cartItem => cartItem.id === item.package_id);
                                            var url_link = "#"
                                            var sent_count = item.sent_count != null ? item.sent_count : 0;

                                            return (
                                                <div className="n-item-card" key={item.id}>
                                                    <div className="n-item-card-left">
                                                        <p className="n-item-date">{item.start_date} - {item.end_date}</p>
                                                        <h3 className="n-item-title">{item.title}</h3>
                                                        <p className="n-item-host">
                                                            <div className="entry-excerpt" style={{ display: 'block', marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: item.description.substring(0, 250) + " ..." || "" }}>
                                                            </div>
                                                        </p>
                                                        <p className="n-item-location">Sent/Target: <b>{sent_count} / {item.target_count}</b></p>
                                                        <button onClick={() => showDetailsModal(item.id, item.title, item.description, item.start_date, item.end_date, item.email_subject, item.email_content, item.email_authority, item.target_count, sent_count, item.image_url)} className="n-item-button">See More</button>
                                                    </div>
                                                    <div className="n-item-card-right">
                                                        <img src={item.image_url} alt={item.title} className="n-item-image" />
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        ) : (
                            <Row style={{ justifyContent: 'center' }}>
                                <h2 className='item-container-title'>Not Found</h2>
                            </Row>
                        )}
                    </Col>
                    <Col xs={0} sm={0} md={2} lg={2}></Col>
                </Row>
            </div>
            <Modal
                type="primary"
                title="Campaign Details"
                width={"90%"}
                open={warningModal}
                footer={null}
                onCancel={handleModalCancel}
            >
                <div className="project-modal">
                    <Row>
                        <Col xs={24} sm={24} md={15} lg={15}>
                            <Row style={{ justifyContent: 'center' }}>
                                <p style={{ fontFamily: "Melodrama", fontSize: 35, marginBottom: 0 }} align="center">{campaignName}</p>
                            </Row><hr />
                            <Row style={{ justifyContent: 'center' }}>
                                <img
                                    src={campaignImageUrl}
                                    alt="Event Image"
                                    style={{
                                        borderRadius: 8,
                                        cursor: 'pointer',
                                        width: '70%',
                                        maxHeight: '300px',
                                        minHeight: '250px'
                                    }}
                                />
                            </Row>
                            <Row style={{ justifyContent: 'center' }}>
                                <b>{campaignStartDate} - {campaignEndDate}</b><br />
                            </Row><hr />
                            <Row style={{ justifyContent: 'center' }}>
                                <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: campaignDesc != "" ? campaignDesc : "" }}></p>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <div style={styles.container}>
                                {/* Progress Section */}
                                <div style={styles.progressSection}>
                                    <h1 style={styles.lettersSent}>{campaignSentCount.toLocaleString()} Letters Sent</h1>
                                    <div style={styles.progressBar}>
                                        <div
                                            style={{
                                                ...styles.progress,
                                                width: `${(campaignSentCount / campaignTargetCount) * 100}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <p style={styles.remainingText}>
                                        Only {campaignRemainingCount.toLocaleString()} more until our goal of {campaignTargetCount.toLocaleString()}
                                    </p>
                                </div>

                                {/* Form Section */}
                                <div style={styles.formSection}>
                                    <h2 style={styles.formTitle}>Take Action</h2>
                                    {
                                        campaignRemainingCount > 0 ?
                                            <>
                                                <form style={styles.form} onSubmit={handleSubmit}>
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        placeholder="First Name *"
                                                        value={formData.firstName}
                                                        onChange={handleInputChange}
                                                        style={styles.input}
                                                        required
                                                    />
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        placeholder="Last Name *"
                                                        value={formData.lastName}
                                                        onChange={handleInputChange}
                                                        style={styles.input}
                                                        required
                                                    />
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email *"
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                        style={styles.input}
                                                        required
                                                    />
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        placeholder="Street Address *"
                                                        value={formData.address}
                                                        onChange={handleInputChange}
                                                        style={styles.input}
                                                        required
                                                    />
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        placeholder="City *"
                                                        value={formData.city}
                                                        onChange={handleInputChange}
                                                        style={styles.input}
                                                        required
                                                    />
                                                    <input
                                                        type="text"
                                                        name="postalCode"
                                                        placeholder="Zip/Postal Code *"
                                                        value={formData.postalCode}
                                                        onChange={handleInputChange}
                                                        style={styles.input}
                                                        required
                                                    />
                                                    <button type="submit" style={styles.button}>
                                                        Start Writing
                                                    </button>
                                                </form>
                                            </>
                                            :
                                            <>
                                                <h2>Target achieved! You can no longer send more emails for this campaign.</h2>
                                            </>
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal >
        </div >
    );
};
const styles = {
    container: {
        width: "300px",
        margin: "0 auto",
        fontFamily: "Arial, sans-serif",
        textAlign: "center",
    },
    progressSection: {
        marginBottom: "20px",
    },
    lettersSent: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    progressBar: {
        width: "100%",
        height: "10px",
        backgroundColor: "#e0e0e0",
        borderRadius: "5px",
        overflow: "hidden",
        marginBottom: "10px",
    },
    progress: {
        height: "100%",
        backgroundColor: "#4caf50",
    },
    remainingText: {
        fontSize: "14px",
        color: "#555",
    },
    formSection: {
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    },
    formTitle: {
        fontSize: "18px",
        marginBottom: "20px",
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        padding: "10px",
        marginBottom: "10px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        fontSize: "14px",
    },
    note: {
        fontSize: "12px",
        color: "#d9534f",
        marginBottom: "10px",
    },
    button: {
        padding: "10px",
        backgroundColor: "#555",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "14px",
    },
};


export default LetterCampaignList;
