import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Popover, notification } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../styled';
import { useHistory } from 'react-router-dom';
import { Cards } from '../../../components/cards/frame/cards-frame';
import axios from 'axios';
import { alertModal } from '../../../components/modals/antd-modals';

function FundUnityPlansTable({ fundunityPlansData, sendDataToParent }) {

    const { isAuthenticate } = useSelector(state => {
        return {
            isAuthenticate: state.fb.auth.uid
        };
    });

    const history = useHistory();
    const usersTableData = [];
    //const [fundunityPlansData, setfundunityPlansData] = useState([]);  
    const [editFeedvisible, setEditFeedVisible] = useState(null);
    const [feedID, setfeedID] = useState(null);


    const showEditModal = (plan_id) => {
        //setEditFeedVisible(true);
        sendDataToParent(plan_id);
    }

    const showDeleteModal = (plan_id) => {
        //const confirm = window.confirm('Are you sure you want to delete this initiative?');
        alertModal.confirm({
            title: 'Are you sure you want to delete this plan?',
            content: '',
            onOk() {
                let final_array = { plan_id: plan_id ,uid:isAuthenticate,}
                axios.post(`${process.env.REACT_APP_API_URL}/delete-fundunity-plan`, final_array)
                    .then(function (response) {
                        if (response.data.status == true) {
                            notification.success({
                                message: "Fundunity plan deleted successfully",
                            });
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        }
                    })
                    .catch(function (error) {
                        notification.error({
                            message: "Someting went wrong"
                        });
                        //handleImportCancel();
                        return false;
                    });
            },
            onCancel() { },
        });
    }


    if (0 < fundunityPlansData.length && fundunityPlansData != null) {
        fundunityPlansData.map((val) => {
            const { plan_id, name, description, price, duration, max_email_sent, max_sms_sent, max_mms_sent, max_facebook_post, max_instagram_post, max_linkeidn_post,
                max_tiktok_post, max_youtube_post, max_x_post, max_feeds_allowed, max_events_allowed, max_fund_initiative_allowed,max_letter_campaign_allowed, created_at, updated_at } = val;
            const truncatedName = name.length > 20 ? `${name.substring(0, 20)}...` : name;
            const truncatedDescription = description.length > 40 ? `${description.substring(0, 40)}...` : description;
            return usersTableData.push({
                key: plan_id,
                name: truncatedName,
                description: truncatedDescription,
                price: price,
                duration: duration,
                max_email_sent: max_email_sent,
                max_sms_sent: max_sms_sent,
                max_mms_sent: max_mms_sent,
                max_facebook_post: max_facebook_post,
                max_instagram_post: max_instagram_post,
                max_linkeidn_post: max_linkeidn_post,
                max_tiktok_post: max_tiktok_post,
                max_youtube_post: max_youtube_post,
                max_x_post: max_x_post,
                max_feeds_allowed: max_feeds_allowed,
                max_events_allowed: max_events_allowed,
                max_fund_initiative_allowed: max_fund_initiative_allowed,
                max_letter_campaign_allowed:max_letter_campaign_allowed,
                created_at: (<p><FeatherIcon icon="calendar" size={14} /> {created_at}</p>),
                updated_at: (<p><FeatherIcon icon="calendar" size={14} /> {updated_at}</p>),
                // status: (
                //   <span
                //     className={`status ${status === 1 ? 'Success' : 'error'
                //       }`}
                //   >
                //     {status === 1 ? 'Active' : 'Inactive'}
                //   </span>
                // ),
                action: (
                    <>
                        <Link to="#" style={{ padding: 10 }}
                            onClick={() => { showEditModal(plan_id) }}
                        >
                            <FeatherIcon size={16} icon="edit" />
                        </Link>
                        <Link to="#" style={{ padding: 10 }}
                            onClick={() => { showDeleteModal(plan_id) }}
                        ><FeatherIcon size={16} icon="trash" /></Link>
                    </>
                )
            });
        });
    }


    const usersTableColumns = [
        {
            title: 'Name',
            align: 'center',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Price',
            align: 'center',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Description',
            align: 'center',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Duration (In days)',
            align: 'center',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Max Email Sent',
            align: 'center',
            dataIndex: 'max_email_sent',
            key: 'max_email_sent',
        },
        {
            title: 'Max SMS Sent',
            align: 'center',
            dataIndex: 'max_sms_sent',
            key: 'max_sms_sent',
        },
        {
            title: 'Max MMS Sent',
            align: 'center',
            dataIndex: 'max_mms_sent',
            key: 'max_mms_sent',
        },
        {
            title: 'Max Facebook Posts',
            align: 'center',
            dataIndex: 'max_facebook_post',
            key: 'max_facebook_post',
        },
        {
            title: 'Max Instagram Posts',
            align: 'center',
            dataIndex: 'max_instagram_post',
            key: 'max_instagram_post',
        },
        {
            title: 'Max Linkedin Posts',
            align: 'center',
            dataIndex: 'max_linkeidn_post',
            key: 'max_linkeidn_post',
        },
        {
            title: 'Max Tiktok Posts',
            align: 'center',
            dataIndex: 'max_tiktok_post',
            key: 'max_tiktok_post',
        },
        {
            title: 'Max Youtube Posts',
            align: 'center',
            dataIndex: 'max_youtube_post',
            key: 'max_youtube_post',
        },
        {
            title: 'Max X Posts',
            align: 'center',
            dataIndex: 'max_x_post',
            key: 'max_x_post',
        },
        {
            title: 'Max Feed/News',
            align: 'center',
            dataIndex: 'max_feeds_allowed',
            key: 'max_feeds_allowed',
        },
        {
            title: 'Max Events',
            align: 'center',
            dataIndex: 'max_events_allowed',
            key: 'max_events_allowed',
        },
        {
            title: 'Max Fund Initiatives',
            align: 'center',
            dataIndex: 'max_fund_initiative_allowed',
            key: 'max_fund_initiative_allowed',
        },
        {
            title: 'Max Letter Campaign',
            align: 'center',
            dataIndex: 'max_letter_campaign_allowed',
            key: 'max_letter_campaign_allowed',
        },
        {
            title: 'Create Date',
            align: 'center',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Update Date',
            align: 'center',
            dataIndex: 'updated_at',
            key: 'updated_at',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
        },
    ];

    const rowSelection = {
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };

    const onCompanyCancel = () => {
        setEditFeedVisible(false);
        setfeedID(null);

        //getAllFeeds();
    };


    return (
        <Cards headless>
            <UserTableStyleWrapper>
                <TableWrapper className="table-responsive">
                    <Table
                        // rowSelection={rowSelection}
                        dataSource={usersTableData}
                        columns={usersTableColumns}
                        pagination={{
                            defaultPageSize: 5,
                            total: usersTableData.length,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                    />
                </TableWrapper>
            </UserTableStyleWrapper>

        </Cards>

    );
}


export default FundUnityPlansTable;
