import { Input, Col, Row, Space, Spin, Button } from 'antd'

import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
//import withoutLoginLayout from '../../../../src/layout/withoutLoginLayout';
const NewsEventAllActiveList = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [newsData, setNewsData] = useState([]);
    const [searchData, setsearchData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get-feeds/null/all`)
            .then(function (response) {
                console.log("response", response)
                if (response.data.length > 0) {
                    setNewsData(response.data)
                    setsearchData(response.data)
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    const redirectURL = (url) => {
        //history.push(url);
        window.open(url, '_blank');
    };

    const searchRecords = (searchText) => {
        console.log("searchText:", searchText);
        // Filter newsData based on searchText
        const filteredData = newsData.filter(item =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            (item.location && item.location.toLowerCase().includes(searchText.toLowerCase())) ||
            item.link.toLowerCase().includes(searchText.toLowerCase())
        );

        console.log("Filtered Data:", filteredData);
        // Update the searchData state with filtered data
        setsearchData(filteredData);
    };
    const showDetailsModal = (link) => {
        window.open(link, '_blank');
    };
    return (
        <div style={{
            width: '100%',
        }}>

            <div>
                <div style={{ marginTop: 10, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                        <Col xs={24} sm={24} md={20} lg={20}>
                            <Row justify="center" style={{ margin: '10px 0' }}>
                                <Col span={24} md={12}>
                                    <h2 className='item-container-title'>News Feed</h2>
                                </Col>
                                <Col span={24} md={12}>
                                    <Input type='text' placeholder='Search here' onChange={(e) => { searchRecords(e.target.value) }} />
                                </Col>
                            </Row>
                            <div>
                                <div className="n-items-list">
                                    {searchData.map((item) => {
                                        if (item) {
                                            let imagesURL = item.image;

                                            return (
                                                <div className="n-item-card" key={item.id}>
                                                    <div className="n-item-card-left">
                                                        <p className="n-item-date">{item.convert_date}</p>
                                                        <h3 className="n-item-title">{item.title}</h3>
                                                        <p className="n-item-host">
                                                            <div className="entry-excerpt" style={{ display: 'block', marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: item.description.substring(0, 250) + " ..." || "" }}>
                                                            </div>
                                                        </p>
                                                        <button onClick={() => showDetailsModal(item.link)} className="n-item-button">See More</button>
                                                    </div>
                                                    <div className="n-item-card-right">
                                                        <img src={imagesURL} alt={item.title} className="n-item-image" />
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>

                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                    </Row>
                </div>

            </div>
        </div>

    )
}

export default NewsEventAllActiveList;
//export default withoutLoginLayout(NewsEventAllActiveList);

