import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, Upload, TimePicker, InputNumber, message, Button, DatePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { FormValidationWrap } from '../../forms/overview/Style';
import styled from "styled-components";
import moment from 'moment';
import axios from 'axios';


const { Option } = Select;

const dateFormat = 'MM/DD/YYYY';
const format = 'h:mm a';



const { TextArea } = Input;

const CreateFundPlan = ({ visible, onCancel }) => {

    const dispatch = useDispatch();
    const isFbAuthenticate = useSelector(state => {
        return {
            isFbAuthenticate: state.fb.auth.uid,
        }
    });

    const [form] = Form.useForm();
    const formData = new FormData();
    //const [is_framed, setIsFramed] = useState(false);

    const [state, setState] = useState({
        visible,
        modalType: 'primary',
        checked: [],
        title: '',
        description: '',
        link: '',
        image: '',
        status: '',
        isLoading: false
    });


    const [image, setImage] = useState('');

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);
    const [buttonDisable, setButton] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOk = (values) => {
        let post_data = {
            uid: isFbAuthenticate.isFbAuthenticate,
            ...values,
        };

        console.log(values);

        setButton(true);
        setLoading(true);

        setState({
            ...state,
            isLoading: true,
        });


        console.log(post_data);

        const formData = new FormData();
        formData.append('data', JSON.stringify(post_data))
        setButton(true);
        setLoading(true);
        setState({
            ...state,
            isLoading: true,
        });

        axios.post(`${process.env.REACT_APP_API_URL}/create-fundunity-plan`, formData)
            .then((response) => {
                if (response.data.status == true) {
                    alert("The Fundunity plan created successfully.");
                    //window.location.reload();
                    onCancel();
                } else {
                    setButton(false);
                    setLoading(false);
                    alert("Uanble to create the fundunity plan");
                }
            })
            .catch((error) => {
                console.log("Error :", error)
            })

    };
    const handleCancel = () => {
        onCancel();
    };
    return (

        <>

            <Modal
                type={state.modalType}
                title="Create Fundunity Plan"
                visible={state.visible}
                width={1000}
                backdrop="static"
                footer={[null]}
                onCancel={handleCancel}
            >
                <div className="project-modal">
                    <>
                        <FormValidationWrap>
                            <Form form={form} name="sDash_validation-form" layout="vertical" onFinish={handleOk} encType="multipart/form-data">
                                <Form.Item name="name" label="Name"
                                    rules={[{ required: true, message: 'Please input plan name' }]}

                                >
                                    <Input placeholder="Plan Name" name="name" />
                                </Form.Item>

                                <Form.Item name="description" label="Description"
                                    rules={[{ required: true, message: 'Please input description regarding plan' }]}
                                >
                                    <TextArea rows={3} type='text' name='description' />
                                </Form.Item>

                                <Row gutter={15}>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Please input price' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Price" type="number" name="price" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="duration" label="Duration for plan(In days)" rules={[{ required: true, message: 'Please input duration' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Duration for plan(In days)" type="number" name="duration" />
                                        </Form.Item>

                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_events_allowed" label="Maximum Events allowed" rules={[{ required: true, message: 'Please input Maximum Events allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum Events allowed" type="number" name="max_events_allowed" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_fund_initiative_allowed" label="Maximum Fund Initiatives allowed" rules={[{ required: true, message: 'Please input Maximum Fund Initiatives allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum Fund Initiatives allowed" type="number" name="max_fund_initiative_allowed" />
                                        </Form.Item>

                                    </Col>
                                </Row>
                                <Row gutter={15}>

                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_sms_sent" label="Maximum SMS sent allowed" rules={[{ required: true, message: 'Please input Maximum SMS sent allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum SMS sent allowed" type="number" name="max_sms_sent" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_mms_sent" label="Maximum MMS sent allowed" rules={[{ required: true, message: 'Please input Maximum MMS sent allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum MMS sent allowed" type="number" name="max_mms_sent" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_email_sent" label="Maximum email sent allowed" rules={[{ required: true, message: 'Please input Maximum email sent allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum email sent allowed" type="number" name="max_email_sent" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_facebook_post" label="Maximum FaceBook posts allowed" rules={[{ required: true, message: 'Please input "Maximum FaceBook posts allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum FaceBook posts allowed" type="number" name="max_facebook_post" />
                                        </Form.Item>

                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_instagram_post" label="Maximum Instagram posts allowed" rules={[{ required: true, message: 'Please input Maximum Instagram posts allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum Instagram posts allowed" type="number" name="max_instagram_post" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_linkeidn_post" label="Maximum Linkedin posts allowed" rules={[{ required: true, message: 'Please input Maximum Linkedin posts allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum Linkedin posts allowed" type="number" name="max_linkeidn_post" />
                                        </Form.Item>

                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_tiktok_post" label="Maximum Tiktok posts allowed" rules={[{ required: true, message: 'Please input Maximum Tiktok posts allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum Tiktok posts allowed" type="number" name="max_tiktok_post" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_youtube_post" label="Maximum Youtube posts allowed" rules={[{ required: true, message: 'Please input Maximum Youtube posts allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum Youtube posts allowed" type="number" name="max_youtube_post" />
                                        </Form.Item>

                                    </Col>
                                </Row>
                                <Row gutter={15}>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_x_post" label="Maximum X posts allowed" rules={[{ required: true, message: 'Please input Maximum X posts allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum X posts allowed" type="number" name="max_x_post" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_feeds_allowed" label="Maximum Feeds allowed" rules={[{ required: true, message: 'Please input Maximum Feeds allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum Feeds allowed" type="number" name="max_feeds_allowed" />
                                        </Form.Item>

                                    </Col>
                                    <Col md={12} xs={24} sm={12} >
                                        <Form.Item name="max_letter_campaign_allowed" label="Maximum letter campaign allowed" rules={[{ required: true, message: 'Please input Maximum letter campaign allowed' }]}>
                                            <InputNumber style={{ width: 300 }} placeholder="Maximum letter campaign allowed" type="number" name="max_letter_campaign_allowed" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item>

                                    <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                                        Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                                    </Button>
                                    {' '}
                                    <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                </Form.Item>
                            </Form>
                        </FormValidationWrap>
                    </>

                </div>
            </Modal>
        </>
    );
};

CreateFundPlan.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default CreateFundPlan;