import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, InputNumber, Select, Col, Row, Upload, TimePicker, message, Button, DatePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { FormValidationWrap } from '../../forms/overview/Style';
import axios from 'axios';


const { TextArea } = Input;

const EditFundunityPlan = ({ visible, Id, onCancel, editData }) => {

    const dispatch = useDispatch();

    const { isAuthenticate } = useSelector(state => {
        return {
            isAuthenticate: state.fb.auth.uid
        };
    });


    const [form] = Form.useForm();
    const formData = new FormData();
    const [ShowForm, setShowForm] = useState(false);

    const [state, setState] = useState({
        visible,
        modalType: 'primary',
        isLoading: false
    });

    useEffect(() => {
        if (editData) {
            try {
                getElementData();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

    }, [Id]);

    const getElementData = async () => {
        try {
            setShowForm(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);
    const [buttonDisable, setButton] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOk = (values) => {
        let post_data = {
            plan_id: editData.plan_id,
            ...values,
        };

        if (isAuthenticate == process.env.REACT_APP_ADMIN_UID) {
            post_data = {
                uid: isAuthenticate,
                plan_id: editData.plan_id,
                ...values,
            };
        }
        setButton(true);
        setLoading(true);

        setState({
            ...state,
            isLoading: true,
        });
        // console.log(post_data);
        const formData = new FormData();
        formData.append('data', JSON.stringify(post_data))
        setButton(true);
        setLoading(true);
        setState({
            ...state,
            isLoading: true,
        });

        axios.post(`${process.env.REACT_APP_API_URL}/update-fundunity-plan`, formData)
            .then((response) => {
                if (response.data.status == true) {
                    alert("The fundunity plan updated successfully.");
                    window.location.reload();
                    //onCancel();
                } else {
                    setButton(false);
                    setLoading(false);
                    alert("Uanble to update the plan");
                }
            })
            .catch((error) => {
                console.log("Error :", error)
            })

    };
    const handleCancel = () => {
        onCancel();
    };

    return (

        <>

            <Modal
                type={state.modalType}
                title="Edit Fundunity Plan"
                visible={state.visible}
                width={1000}
                backdrop="static"
                footer={[null]}
                onCancel={handleCancel}
            >
                <div className="project-modal">
                    <>
                        <FormValidationWrap>
                            {ShowForm == true &&
                                <>
                                    <Form form={form} name="sDash_validation-form" layout="vertical" onFinish={handleOk} encType="multipart/form-data">

                                        <Form.Item name="name" label="Name"
                                            rules={[{ required: true, message: 'Please input name' }]}
                                            initialValue={editData ? editData.name : ''}
                                        >
                                            <Input placeholder="Plan Name" name="name" />
                                        </Form.Item>

                                        <Form.Item name="description" label="Description"
                                            rules={[{ required: true, message: 'Please input description' }]}
                                            initialValue={editData ? editData.description : ''}
                                        >
                                            <TextArea rows={3} type='text' name='description' />
                                        </Form.Item>

                                        <Row gutter={15}>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Please input price' }]}
                                                    initialValue={editData ? editData.price : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Price" type="number" name="price" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="duration" label="Duration for plan(In days)" rules={[{ required: true, message: 'Please input duration' }]}
                                                    initialValue={editData ? editData.duration : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Duration for plan(In days)" type="number" name="duration" />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={15}>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_events_allowed" label="Maximum Events allowed" rules={[{ required: true, message: 'Please input Maximum Events allowed' }]}
                                                    initialValue={editData ? editData.max_events_allowed : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum Events allowed" type="number" name="max_events_allowed" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_fund_initiative_allowed" label="Maximum Fund Initiatives allowed" rules={[{ required: true, message: 'Please input Maximum Fund Initiatives allowed' }]}
                                                    initialValue={editData ? editData.max_fund_initiative_allowed : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum Fund Initiatives allowed" type="number" name="max_fund_initiative_allowed" />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={15}>

                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_sms_sent" label="Maximum SMS sent allowed" rules={[{ required: true, message: 'Please input Maximum SMS sent allowed' }]}
                                                    initialValue={editData ? editData.max_sms_sent : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum SMS sent allowed" type="number" name="max_sms_sent" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_mms_sent" label="Maximum MMS sent allowed" rules={[{ required: true, message: 'Please input Maximum MMS sent allowed' }]}
                                                    initialValue={editData ? editData.max_mms_sent : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum MMS sent allowed" type="number" name="max_mms_sent" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={15}>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_email_sent" label="Maximum email sent allowed" rules={[{ required: true, message: 'Please input Maximum email sent allowed' }]}
                                                    initialValue={editData ? editData.max_email_sent : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum email sent allowed" type="number" name="max_email_sent" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_facebook_post" label="Maximum FaceBook posts allowed" rules={[{ required: true, message: 'Please input "Maximum FaceBook posts allowed' }]}
                                                    initialValue={editData ? editData.max_facebook_post : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum FaceBook posts allowed" type="number" name="max_facebook_post" />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={15}>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_instagram_post" label="Maximum Instagram posts allowed" rules={[{ required: true, message: 'Please input Maximum Instagram posts allowed' }]}
                                                    initialValue={editData ? editData.max_instagram_post : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum Instagram posts allowed" type="number" name="max_instagram_post" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_linkeidn_post" label="Maximum Linkedin posts allowed" rules={[{ required: true, message: 'Please input Maximum Linkedin posts allowed' }]}
                                                    initialValue={editData ? editData.max_linkeidn_post : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum Linkedin posts allowed" type="number" name="max_linkeidn_post" />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={15}>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_tiktok_post" label="Maximum Tiktok posts allowed" rules={[{ required: true, message: 'Please input Maximum Tiktok posts allowed' }]}
                                                    initialValue={editData ? editData.max_tiktok_post : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum Tiktok posts allowed" type="number" name="max_tiktok_post" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_youtube_post" label="Maximum Youtube posts allowed" rules={[{ required: true, message: 'Please input Maximum Youtube posts allowed' }]}
                                                    initialValue={editData ? editData.max_youtube_post : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum Youtube posts allowed" type="number" name="max_youtube_post" />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={15}>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_x_post" label="Maximum X posts allowed" rules={[{ required: true, message: 'Please input Maximum X posts allowed' }]}
                                                    initialValue={editData ? editData.max_x_post : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum X posts allowed" type="number" name="max_x_post" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_feeds_allowed" label="Maximum Feeds allowed" rules={[{ required: true, message: 'Please input Maximum Feeds allowed' }]}
                                                    initialValue={editData ? editData.max_feeds_allowed : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum Feeds allowed" type="number" name="max_feeds_allowed" />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="max_letter_campaign_allowed" label="Maximum letter campaign allowed" rules={[{ required: true, message: 'Please input Maximum letter campaign allowed' }]}
                                                initialValue={editData ? editData.max_letter_campaign_allowed : ''}>
                                                    <InputNumber style={{ width: 300 }} placeholder="Maximum letter campaign allowed" type="number" name="max_letter_campaign_allowed" />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Form.Item>

                                            <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                                                Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                                            </Button>
                                            {' '}
                                            <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                                                Cancel
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>}
                        </FormValidationWrap>
                    </>

                </div>
            </Modal>
        </>
    );
};

EditFundunityPlan.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default EditFundunityPlan;


