import { Input, Col, Row, Space, Spin, Button } from 'antd'

import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import "../../../container/ItemList.css";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import DonationPackageActiveScrollList from '../donationPackage/DonationPackageActiveScrollList';
import NewsFeedActiveScrollList from '../feed/NewsFeedActiveScrollList';
import EventActiveScrollList from '../event/EventActiveScrollList';
import FundInitiativeActiveScrollList from '../fundInitiative/FundInitiativeActiveScrollList';
import LetterCampaignActiveScrollList from '../letterCampaign/LetterCampaignActiveScrollList';
import FundunityOrganizationsActiveScrollList from '../fundunituOrganizations/FundunityOrganizationsActiveScrollList';

const FundunityAllActiveList = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [newsData, setNewsData] = useState([]);
    const [searchNewsData, setsearchNewsData] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get-feeds/null/all`)
            .then(function (response) {
                if (response.data.length > 0) {
                    setNewsData(response.data.slice(0, 10))
                    setsearchNewsData(response.data.slice(0, 10))
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);
    const searchRecords = (searchText) => {
        console.log("searchText:", searchText);
        // Filter newsData based on searchText
        const filteredNewsData = newsData.filter(item =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            (item.location && item.location.toLowerCase().includes(searchText.toLowerCase())) ||
            item.link.toLowerCase().includes(searchText.toLowerCase())
        );
        setsearchNewsData(filteredNewsData);
    };

    const goToAllNews = () => {
         history.push(`/all-news`);
    }
    const showNewsDetailsModal = (link) => {
        window.open(link, '_blank');
    };

    return (
        <div style={{
            width: '100%',
        }}>

            <div>
                <div style={{ marginTop: 10, paddingBottom: 60 }}>
                    {/* <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                        <Col xs={24} sm={24} md={20} lg={20}>
                            <Row justify="center" style={{ margin: '10px 0' }}>
                                <Col span={24} md={12}>
                                    <h2 className='item-container-title'>Fundunity Items</h2>
                                </Col>
                                <Col span={24} md={12}>
                                    <Input type='text' placeholder='Search here' onChange={(e) => { searchRecords(e.target.value) }} />
                                </Col>
                            </Row>
                            {
                                [...searchNewsData].length > 0 ?
                                    <div>
                                        <h2 className='item-container-title'>News Feed</h2>
                                        <div className="item-scroll">
                                            {searchNewsData.map((item) => {
                                                if (item) {
                                                    let imagesURL = item.image;
                                                    return (
                                                        imagesURL &&
                                                        <div className="item-card" key={item.id}>
                                                            {item.tag && <div className="item-tag">{item.tag}</div>}
                                                            <img src={imagesURL} alt={item.title} className="item-image" />
                                                            <p className="item-date">{item.convert_date}</p>
                                                            <h3 className="item-title">{item.title}</h3>
                                                            <p className="item-host">
                                                                <div className="entry-excerpt" style={{ display: 'block', marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: item.description.substring(0, 150) + " ..." || "" }}>
                                                                </div>
                                                            </p>
                                                            <button onClick={() => showNewsDetailsModal(item.link)} className="n-item-button">Keep Reading</button>

                                                        </div>
                                                    );
                                                }
                                            })}
                                            <div className="item-card see-more-card">
                                                <div className="see-more-content">
                                                    <button onClick={goToAllNews} className="see-more-button">See more</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <Row style={{ justifyContent: 'center' }}>
                                       <h2 className='item-container-title'>Not Found</h2>
                                    </Row>
                            }
                            
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                    </Row> */}
                    
                    <FundunityOrganizationsActiveScrollList />
                    <DonationPackageActiveScrollList />
                    <NewsFeedActiveScrollList />
                    <EventActiveScrollList />
                    <FundInitiativeActiveScrollList />
                    <LetterCampaignActiveScrollList />
                </div>

            </div>
        </div>

    )
}

export default FundunityAllActiveList;

