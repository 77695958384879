import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Popover, notification } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../styled';
import { useHistory } from 'react-router-dom';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import axios from 'axios';
import actions from '../../../redux/organizations/actions';
import { alertModal } from '../../../components/modals/antd-modals';

function InitiativeTable({ letterCampaigningData, sendDataToParent }) {

  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });

  const history = useHistory();
  const usersTableData = [];
  //const [letterCampaigningData, setletterCampaigningData] = useState([]);  
  const [editFeedvisible, setEditFeedVisible] = useState(null);
  const [feedID, setfeedID] = useState(null);


  const showEditModal = (event_id) => {
    //setEditFeedVisible(true);
    sendDataToParent(event_id);
  }

  const showDeleteModal = (id, image) => {
    //const confirm = window.confirm('Are you sure you want to delete this initiative?');
    alertModal.confirm({
      title: 'Are you sure you want to delete this letter campaign?',
      content: '',
      onOk() {
        let final_array = { id: id, images: image }
        axios.post(`${process.env.REACT_APP_API_URL}/delete-letter-campaign`, final_array)
          .then(function (response) {
            if (response.data.status == true) {
              notification.success({
                message: "Letter campaign deleted successfully",
              });
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          })
          .catch(function (error) {
            notification.error({
              message: "Someting went wrong"
            });
            //handleImportCancel();
            return false;
          });
      },
      onCancel() { },
    });
  }


  if (0 < letterCampaigningData.length && letterCampaigningData != null) {
    letterCampaigningData.map((val) => {
      const { id, title, description, start_date, end_date, tags,target_count,sent_count, image_url, status, created_at, updated_at } = val;
      const truncatedTitle = title.length > 20 ? `${title.substring(0, 20)}...` : title;
      const truncatedDescription = description.length > 40 ? `${description.substring(0, 40)}...` : description;
      return usersTableData.push({
        key: id,
        title: truncatedTitle,
        images: (<>{image_url != null && <img src={image_url} width="80" />}</>),
        description: truncatedDescription,
        created_at: (<p><FeatherIcon icon="calendar" size={14} /> {created_at}</p>),
        date: (<p>{start_date}-{end_date}</p>),
        status: (
          <span
            className={`status ${status === 1 ? 'Success' : 'error'
              }`}
          >
            {status === 1 ? 'Active' : 'Inactive'}
          </span>
        ),
        tags: tags,
        target_count: (<>{target_count != null ? target_count :0 }</>),
        sent_count: (<>{sent_count != null ? sent_count :0 }</>),
        action: (
          <>
            <Link to="#" style={{padding:10}}
              onClick={() => { showEditModal(id) }}
            >
              <FeatherIcon size={16} icon="edit" />
            </Link>
            <Link to="#" style={{padding:10}}
              onClick={() => { showDeleteModal(id, image_url) }}
            ><FeatherIcon size={16} icon="trash" /></Link>
          </>
        )
      });
    });
  }


  const usersTableColumns = [
    {
      title: 'Title',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Image',
      align: 'center',
      dataIndex: 'images',
      key: 'images',
    },
    // {
    //   title: 'Description',
    //   align: 'center',
    //   dataIndex: 'description',
    //   key: 'description',
    // },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Start-End Date',
      align: 'center',
      dataIndex: 'date',
      key: 'date',
    },
    // {
    //   title: 'Tags',
    //   align: 'center',
    //   dataIndex: 'tags',
    //   key: 'tags',
    // },
    {
      title: 'Target Count',
      align: 'center',
      dataIndex: 'target_count',
      key: 'target_count',
    },
    {
      title: 'Sent Count',
      align: 'center',
      dataIndex: 'sent_count',
      key: 'sent_count',
    },
    {
      title: 'Create Date',
      align: 'center',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onCompanyCancel = () => {
    setEditFeedVisible(false);
    setfeedID(null);

    //getAllFeeds();
  };


  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            // rowSelection={rowSelection}
            dataSource={usersTableData}
            columns={usersTableColumns}
            pagination={{
              defaultPageSize: 5,
              total: usersTableData.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>

    </Cards>

  );
}


export default InitiativeTable;
