import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route } from 'react-router-dom';//Redirect
//import AuthLayout from '../container/profile/authentication/Index';
//import CheckOut from '../container/ecommerce/overview/CheckOut';
import withoutLoginLayout from '../../src/layout/withoutLoginLayout';
import MapLists from '../container/home/MapLists';
import NewsFeedAllActiveList from '../container/pages/feed/NewsFeedAllActiveList';
import FundunityActiveScrollItems from '../container/pages/fundunityItems/FundunityActiveScrollItems';
import NewsFeedActiveScrollList from '../container/pages/feed/NewsFeedActiveScrollList';
import DonationPackageActiveScrollList from '../container/pages/donationPackage/DonationPackageActiveScrollList';
import DonationPackageAllActiveList from '../container/pages/donationPackage/DonationPackageAllActiveList';


import FundInitiativeActiveScrollList from '../container/pages/fundInitiative/FundInitiativeActiveScrollList';
import FundInitiativeAllActiveList from '../container/pages/fundInitiative/FundInitiativeAllActiveList';

import FundunityOrganizationsActiveScrollList from '../container/pages/fundunituOrganizations/FundunityOrganizationsActiveScrollList';
import FundunityOrganizationsAllActiveList from '../container/pages/fundunituOrganizations/FundunityOrganizationsAllActiveList';

import LetterCampaignAllActiveList from '../container/pages/letterCampaign/LetterCampaignAllActiveList'
import LetterCampaignActiveScrollList from '../container/pages/letterCampaign/LetterCampaignActiveScrollList'
import EventAllActiveListList from '../container/pages/event/EventAllActiveListList';
import EventActiveScrollList from '../container/pages/event/EventActiveScrollList';
import EventDetails from '../container/home/EventDetails';
import LetterCampaignListDataTable from '../container/pages/letterCampaign/LetterCampaignListDataTable';

const OrganizationSignup = lazy(() => import('../container/profile/authentication/overview/OrganizationSignup'));
//const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const FbLogin = lazy(() => import('../container/profile/authentication/overview/FbSignIn'));
const FbSignUp = lazy(() => import('../container/profile/authentication/overview/FbSignup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const ShoppingCard = lazy(() => import('../container/profile/authentication/overview/ShoppingCard'));
const TempCheckOut = lazy(() => import('../container/profile/authentication/overview/TempCheckOut'));
const LadingPage = lazy(() => import('../container/home/LadingPage'));
const AdminLogin = lazy(() => import('../container/profile/authentication/overview/AdminLogin'));
const ProjectDetails = lazy(() => import('../container/project/ProjectDetails'));
const DirectDonationDetails = lazy(() => import('../container/ecommerce/product/ProductDetails'));
const PrivacyPolicy = lazy(() => import('../container/pages/PrivacyPolicy'));
const NewProject = lazy(() => import('../container/pages/NewProject'));
const NewProjectSubmitForm = lazy(() => import('../container/pages/NewProjectSubmitForm'));
//const BlankPage = lazy(() => import('../container/pages/BlankPage'));

/*
function NotFound() {
  return <Redirect to="/" />;
}
*/

function FrontendRoutes() {  
  return (
    <>
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {/* <Route exact path="/apple" component={BlankPage} /> */}
        <Route exact path="/checkout" component={TempCheckOut} />
        <Route path="/org/details/:id" component={ProjectDetails} />
        <Route path="/org/donation/:name/:id" component={DirectDonationDetails} />
        <Route exact path="/forgotPassword" component={ForgotPass} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/organizations-list" component={ShoppingCard} />
        <Route exact path="/admin-login" component={AdminLogin} />
        <Route exact path="/" component={LadingPage} />
        <Route exact path="/fbRegister" component={FbSignUp} />
        <Route exact path="/fbSignIn" component={FbLogin} />
        <Route exact path="/login" component={FbLogin} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/register-organization/:token" component={OrganizationSignup} />
        <Route exact path="/map-listings" component={MapLists} />
        <Route exact path="/all-news" component={NewsFeedAllActiveList} />
        <Route exact path="/fundunity-items" component={FundunityActiveScrollItems} />
        <Route exact path="/news" component={ NewsFeedActiveScrollList} />
        <Route exact path="/donation-package" component={DonationPackageActiveScrollList} />
        <Route exact path="/all-donation-package" component={DonationPackageAllActiveList} />
        <Route exact path="/fund-initiatives" component={FundInitiativeActiveScrollList} />
        <Route exact path="/all-fund-initiatives" component={FundInitiativeAllActiveList} />
        <Route exact path="/fundunity-latest-organizations" component={FundunityOrganizationsActiveScrollList} />
        <Route exact path="/all-fundunity-organizations" component={FundunityOrganizationsAllActiveList} />
        <Route exact path="/letter-campaigning" component={LetterCampaignActiveScrollList} />
        <Route exact path="/all-letter-campaigning" component={LetterCampaignAllActiveList} />
        <Route path={`/event-details/:id`} component={EventDetails} />
        <Route exact path="/events" component={EventActiveScrollList} />
        <Route exact path="/all-events" component={EventAllActiveListList} />
        <Route exact path="/sample-offer-page-url" component={NewProject} />
        <Route exact path="/sample-submit-form-url" component={NewProjectSubmitForm} />
        {/* <Route exact path="*" component={NotFound} /> */}
      </Suspense>      
    </Switch>
    {/* <Route exact path="*" component={NotFound} /> */}
    </>
  );
}

export default withoutLoginLayout(FrontendRoutes);
//export default FrontendRoutes;
