import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import NewsFeedActiveScrollList from '../../container/pages/feed/NewsFeedActiveScrollList';
import EventActiveScrollList from '../../container/pages/event/EventActiveScrollList';

//import MapLists from '../../';
const MapLists = lazy(() => import('../../container/home/MapLists'));
//const Users = lazy(() => import('../../container/pages/Users'));
//const AddUser = lazy(() => import('../../container/pages/AddUsers'));
//const SendTable = lazy(() => import('../../container/pages/UserListDataTable'));
const DataTable = lazy(() => import('../../container/pages/UserListDataTable'));
//const Team = lazy(() => import('../../container/pages/Team'));

function PagesRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* <Route path={`${path}/add-user`} component={AddUser} />
          <Route path={`${path}/team`} component={Team} />
          <Route path={`${path}`} component={Users} />
       */}

       
      <Route path={`${path}/sent-donations/:type/:id/:role`} component={DataTable} />
      <Route path={`${path}/donations/:type/:id/:role`} component={DataTable} />
      <Route path={`${path}/map-listings`} component={MapLists} />
      <Route path={`${path}/news`} component={NewsFeedActiveScrollList} />
      <Route path={`${path}/events`} component={EventActiveScrollList} />
    </Switch>
  );
}

export default PagesRoute;
