import { Input, Col, Row, Modal, Space, Spin, Button, Typography, Pagination } from 'antd'
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';


const FundInitiativeList = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [fundInitiativeData, setFundData] = useState([]);
    const [searchData, setsearchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [warningModal, setWarningModal] = useState(false);

    const [fundInitiativeName, setFundName] = useState("");
    const [fundDesc, setFundDesc] = useState("");
    const [fundAmount, setFundAmount] = useState("");
    const [fundImage, setFundImage] = useState("");
    const [fundStartDate, setFundStartDate] = useState("");
    const [fundEndDate, setFundEndDate] = useState("");


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get-fund-initiative/null/all`)
            .then(function (response) {
                console.log("response", response)
                if (response.data.length > 0) {
                    var fundInitiativeData = []
                    response.data.forEach(element => {
                        fundInitiativeData.push(
                            {
                                initiative_id: element.initiative_id,
                                title: element.title.slice(0, 50),
                                description: element.description.slice(0, 300),
                                start_date: element.start_date,
                                end_date: element.end_date,
                                tags: element.tags,
                                image: element.images,
                                amount: element.amount,
                            }
                        )
                    });
                    setFundData(fundInitiativeData.slice(0, 10))
                    setsearchData(fundInitiativeData.slice(0, 10))
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    const searchRecords = (searchText) => {
        const filteredData = fundInitiativeData.filter(item =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            (item.tags && item.tags.toLowerCase().includes(searchText.toLowerCase()))
        );
        setsearchData(filteredData);
    };

    const showDetailsModal = (id, name, startDate, endDate, description, amount, logos) => {
        setFundName(name);
        setFundStartDate(startDate);
        setFundEndDate(endDate)
        setFundDesc(description)
        setFundAmount(amount)
        setFundImage(logos)
        setWarningModal(true)
    };

    const handleModalCancel = () => {
        setWarningModal(false)
        setFundName("");
        setFundStartDate("");
        setFundEndDate("")
        setFundDesc("")
        setFundAmount("")
        setFundImage("")
    };



    return (
        <div style={{
            width: '100%',
        }}>

            <div>
                <div style={{ marginTop: 10, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                        <Col xs={24} sm={24} md={20} lg={20}>
                            <Row justify="center" style={{ margin: '10px 0' }}>
                                <Col span={24} md={12}>
                                    <h2 className='item-container-title'>Fund Initiatives</h2>
                                </Col>
                                <Col span={24} md={12}>
                                    <Input type='text' placeholder='Search here' onChange={(e) => { searchRecords(e.target.value) }} />
                                </Col>
                            </Row>
                            <div>
                                <div className="n-items-list">
                                    {searchData.map((item) => {
                                        if (item) {
                                            let imagesURL = item.image;
                                            return (
                                                <div className="n-item-card" key={item.initiative_id}>
                                                    <div className="n-item-card-left">
                                                        <p className="n-item-date">{item.start_date} - {item.end_date}</p>
                                                        <h3 className="n-item-title">{item.title}</h3>
                                                        <p className="n-item-host">
                                                            <div className="entry-excerpt" style={{ display: 'block', marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: item.description.substring(0, 250) + " ..." || "" }}>
                                                            </div>
                                                        </p>
                                                        <p className="item-location">Tags: <b>{item.tags}</b></p>
                                                        {
                                                            item.amount && <p className="item-location"><b style={{ fontSize: 23 }}>${item.amount}</b></p>

                                                        }
                                                        <button onClick={() => showDetailsModal(item.initiative_id, item.title, item.start_date, item.end_date, item.description, item.amount, imagesURL)} className="n-item-button">See More</button>
                                                    </div>
                                                    <div className="n-item-card-right">
                                                        <img src={imagesURL} alt={item.title} className="n-item-image" />
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                    </Row>
                </div>

            </div>
            <Modal
                type="primary"
                title="Fund Initiative Details"
                width={"80%"}
                open={warningModal}
                footer={null}
                onCancel={handleModalCancel}
            >
                <div className="project-modal">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Row style={{ justifyContent: 'center',marginBottom:20 }}>
                            <img
                                src={fundImage}
                                alt={fundInitiativeName}
                                style={{
                                    borderRadius: 8,
                                    cursor: 'pointer',
                                    width: '70%',
                                    maxHeight: '300px',
                                    minHeight: '250px'
                                }}
                            />
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                            <h2 className='item-container-title'>{fundInitiativeName}</h2>
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                            <p className="n-item-date">{fundStartDate} - {fundEndDate}</p>
                        </Row>

                        <Row style={{ justifyContent: 'center' }}>
                            <p style={{ fontFamily: "Arial", fontSize: 20, fontStyle: 'italic', marginBottom: 0 }} align="center">
                                <div class="entry-meta">
                                    {
                                        fundAmount && <p className="item-location"><b style={{ fontSize: 23 }}>${fundAmount}</b></p>

                                    }
                                </div>
                            </p>
                        </Row><br />
                        <Row style={{ justifyContent: 'center' }}>
                            <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: fundDesc != "" ? fundDesc : "" }}></p>
                        </Row>
                    </Col>
                </div>
            </Modal>
        </div>
    )
}

export default FundInitiativeList;
